<template>
  <div v-if="loading"><progress class="progress is-small is-primary" max="100">15%</progress></div>
  <div class="box">
    <div class="field">
      <label class="label">Key</label>
      <div class="control">
        <input class="input" type="text" v-model="key">
      </div>
    </div>
    <button class="button is-primary"  v-on:click="sendKey">Sign in</button>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: function () {
    return {
      loading: false,
      key: '',
      lsKey: 'KEY'
    }
  },
  methods: {
    sendKey: function () {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/keys:check`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: this.key
      }).then(r => {
        setTimeout(() => {
          if (r.status !== 404) {
            localStorage.setItem(this.lsKey, this.key)
            this.$router.push('/lists')
          }
          this.loading = false;
        }, 2000);
      })
    }
  }, beforeMount() {
    this.loading = true;
    this.key = localStorage.getItem(this.lsKey) != 'null' ? localStorage.getItem(this.lsKey) : '';
    this.sendKey();
  }
}
</script>
